jQuery($ => {

	/* Toggle Header Search */
	$(".nav__item__search a").click(function (e) {
		$(this).parent().toggleClass("active");
		$(".page__header__section__search").toggleClass("active");
		$(".page__header__section__search input[type=search]").focus();
		e.preventDefault();
	});

	const image = document.getElementsByClassName('parallax');
	new simpleParallax(image, {
		orientation: 'down'
	});

	/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
	let prevScrollpos = window.pageYOffset;
	window.onscroll = function() {
		const currentScrollPos = window.pageYOffset;
		if (prevScrollpos < 100) {
			const element = document.getElementById("page__header");
			element.classList.add("scroll-top");
		} else if (prevScrollpos > currentScrollPos) {
			const element = document.getElementById("page__header");
			element.classList.remove("scroll-down");
			element.classList.remove("scroll-top");
			element.classList.add("scroll-up");
		} else {
			const element = document.getElementById("page__header");
			element.classList.add("scroll-down");
			element.classList.remove("scroll-top");
			element.classList.remove("scroll-up");
		}
		prevScrollpos = currentScrollPos;
	}

	// dynamic height setting for list elements
	const $elementsWithDynamicHeight = $('.listelement__content.dynamic-height');
	if( $elementsWithDynamicHeight.length ) {
		let maxHeight = 0;
		$elementsWithDynamicHeight.each((i,el) => {
			if( el.offsetHeight > maxHeight ) {
				maxHeight = el.offsetHeight;
			}
		});
		$elementsWithDynamicHeight.each((i, el) => {el.style.minHeight = maxHeight + 'px'});
	}

})

/* Mobile menu */
Mmenu.configs.classNames.selected = "active";
Mmenu.configs.offCanvas.page.selector = "#page";

const menu = new Mmenu( "#menu", {
	slidingSubmenus: true,
	extensions: ["theme-dark"]
});

new Mmenu("nav#menu", {
	extensions: [
		"position-right"
	]
}, {
	// configuration
});

/* Horizontal Dropdown Navigation */
$('ul.sf-menu').superfish();
